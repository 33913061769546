


// Google Map showroom Location
import logo from 'public/assets/logos/OG00008_032621_PinterestProfilePhoto.jpg'


export const showroomLocationMap = {
    center: { 
        lat: 33.78878354689432, 
        lng: -84.42430503191379 
    },
    zoom: 16,
    infoWindowContent: `
        <div id="content" style="padding:1rem; display:flex; align-items:center">
            <img src="${logo.src}" alt="at logo" width="75" height="75" />

            <div style="padding-left:1rem">
                <h2 id="firstHeading">
                    AuthenTEAK Atlanta Showroom
                </h2>
                <div id="bodyContent">
                    <p style="margin:0">
                        Retail showroom for a selection of outdoor<br/>
                        kitchens, grills & fire pits, seating, planters & more.
                    </p>
                </div>
            </div>
        </div>`
}
